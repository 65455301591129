
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Card, CardGroup } from 'react-bootstrap';
import './DungeonGenerationInfoPanel.css';
import MainDungeonShot from './../../images/dungeonGeneration/screenshot_dungeon2.png';
import d_origin from './../../images/dungeonGeneration/dungeonOrigin.png';
import d_text from './../../images/dungeonGeneration/dungeonText.png';
import d_render from './../../images/dungeonGeneration/dungeonRender.png';
/*const options = [
  {
    name: 'Enable backdrop (default)',
    scroll: false,
    backdrop: true,
  },
  {
    name: 'Disable backdrop',
    scroll: false,
    backdrop: false,
  },
  {
    name: 'Enable body scrolling',
    scroll: true,
    backdrop: false,
  },
  {
    name: 'Enable both scrolling & backdrop',
    scroll: true,
    backdrop: true,
  },
];*/

function LockwoodPublishingInfoPanel({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);
  //https://react-bootstrap.github.io/components/cards/
  return (
    <>
      <button className='astext' onClick={toggleShow}><u>Read More</u></button>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><h1>Lockwood Publishing</h1></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ContentBlock>
          </ContentBlock>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default LockwoodPublishingInfoPanel;

function ContentBlock() {
  return (
    <>
      <Card>
        <Card.Header ><h5>Overview </h5>
          <a href="https://lockwoodpublishing.com" target="_blank">Visit their website</a>
        </Card.Header>

        <Card.Body>
          <Card>
            <Card.Body>
              <Card.Text>
                <h2>Key Tech</h2>
                <ul>
                  <li>Unity</li>
                  <li>C#</li>
                  <li>AWS</li>
                  <li>Node.js</li>
                  <li>React.js</li>
                  <li>Android/iOS</li>
                </ul>

                <h2>Role</h2>
                <p>My role is Lockwood was to lead a multinational team to develop and concept potential technology to use either within their flagship application 'Avakin Life'
                  or for any potential endevours outside.
                  I helped run a team ranging from 6 people to 15 to plan, investigate, develop, and publish multiple cutting edge technologies
                  all focusing around a 3d character editor for mobile devices. Due to mainly targeting mobile it was pivotal that we made sure the applications' performance was acceptable.
                  I was a key driving force behind the coding standards, project layout & setup, practices within development, and how the end product was developed. I also took a leading role
                  in the project management and discussions around where the product should go or what it should be. This was aided by the Scrum master training I completed during my time at Lockwood.</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </>
  );
}