
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Card, CardGroup } from 'react-bootstrap';
import './DungeonGenerationInfoPanel.css';
import MainDungeonShot from './../../images/dungeonGeneration/screenshot_dungeon2.png';
import d_origin from './../../images/dungeonGeneration/dungeonOrigin.png';
import d_text from './../../images/dungeonGeneration/dungeonText.png';
import d_render from './../../images/dungeonGeneration/dungeonRender.png';
/*const options = [
  {
    name: 'Enable backdrop (default)',
    scroll: false,
    backdrop: true,
  },
  {
    name: 'Disable backdrop',
    scroll: false,
    backdrop: false,
  },
  {
    name: 'Enable body scrolling',
    scroll: true,
    backdrop: false,
  },
  {
    name: 'Enable both scrolling & backdrop',
    scroll: true,
    backdrop: true,
  },
];*/

function DungeonGenerationPanel({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);
  //https://react-bootstrap.github.io/components/cards/
  return (
    <>
      <button className='astext' onClick={toggleShow}><u>Read More</u></button>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><h1>DUNGEON GENERATION</h1></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ContentBlock />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default DungeonGenerationPanel;

function ContentBlock() {
  return (
    <>
      <Card>
        <Card.Header as="h5">Overview</Card.Header>
        <Card.Img variant="top" src={MainDungeonShot} />
        <Card.Body>
          <CardGroup>
            <Card>
              <Card.Body>
                <Card.Title>Core Generation</Card.Title>
                <Card.Text>
                  <ol>
                    <li>Generate Grid</li>
                    <li>Generate Rooms</li>
                    <li>Generate Hallways</li>
                    <li>Connect Rooms to Hallways (Generate door type)</li>
                  </ol>
                </Card.Text>
              </Card.Body>
              <Card.Img variant="bottom" height='300px' width='auto' src={d_origin} />
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Customisation Options</Card.Title>
                <Card.Text>
                  <ol>
                    <li>Strip Deadends</li>
                    <li>Apply Threat Locations</li>
                    <li>Apply Reward Locations</li>
                    <li>Apply <i>Lore</i> Locations</li>
                    <li>Optional Tiling</li>
                  </ol>
                </Card.Text>
              </Card.Body>
              <Card.Img variant="bottom" height='300px' width='auto' position='centre' src={d_text} />
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Unity Specific</Card.Title>
                <Card.Text>
                  <ol>
                    <li>Stair Location</li>
                    <li>Light Location</li>
                    <li>Tile Selection</li>
                    <li>Tile Matching</li>
                  </ol>
                </Card.Text>
              </Card.Body>
              <Card.Img variant="bottom" height='300px' width='auto' src={d_render} />
            </Card>
          </CardGroup>
        </Card.Body>
      </Card>
      <br></br>
      <Card>
        <Card.Header as="h5">Core Generation</Card.Header>
        <Card.Body>
          <h4>Grid</h4>
          <p>The grid itself is very simple. A class representing a cell will be used to create a collection that represents the grid or map that we have.
            Each one knows about its neighbours in turn for easy generation when it comes to the rooms/corridors. The grid contains a variety of helper methods used during generation to return helpful information.
            Some of this information are things like Rooms which ultimately are just a collection of cells interlinked.</p>
          <h4>Rooms</h4>
          <p>Room is the second thing to be generated. These are the simplest part of the algorithm. The first step is to ascertain how busy we want the grid to be; how many rooms we want.
            For this we set a limit of loop iterations to conduct - the more rooms required the more iterations of the room placement code. This is quite a simple approach, but it works for the effect I was going for.</p>
          <p>For each loop a random Cell in the grid is selected. It will be checked to see if it is already in use as a room or linked to a room in any way. If it is <b>not</b> taken, then it will be selected for a room.
            Before it can be placed, however the size of the room gets calculated and then we check to see if another room is intersected/blocking this room.
            Once again, if it is not hampered by any other room the linking of cells will be conducted and the room will be added to a collection within the grid.
            Part of this allows easy alteration to the code if we wanted room so be able to merge into a single larger, non-uniform room.</p>
          <h4>Hallways</h4>
          <p>For the hallways, I trialed a variety of maze algorithms for their production. After trialing many and narrowing it down to a few
            - Hunt-To-Kill, and Recursive Backtracker - and playing around with the settings I settled on Recursive Backtracking as the algorithm to use for corridor generation.
            Although Recursive Backtracking is used for the actual corridors, I do implement Hunt-To-Kill in a sense to make sure everywhere has been visited.
            This is due to rooms blocking entire areas from the maze generation so in order to make sure it has finished I first need to make sure there are no unvisited/unlinked cells.</p>
          <h4>Connecting</h4>
          <p>This is where knowing each cell about its neighbours comes in really helpful.
            I loop through all dead ends (stored from the generation above) and check which are neighbouring rooms. If they are neighbouring a room the they will get connected.
            Once this has been done, I will check to see if any rooms in turn have been left without a connection to the corridors.
            If this is the case I will randomly select a location on its periphery and connect that to the hallway.
          </p>
          <p>After all these steps are complete we are left with a Grid that knows about its Rooms, Deadends, and doorways. We know have a dungeon that can be taken and used where ever one might need one.
            It is also worth noting that there are options to have it be random (without a seed number) or seeded random (with seed number). The seeded random will ensure the dungeon is the same every time a given seed is used.
          </p>
        </Card.Body>
      </Card>
      <br></br>
      <Card>
        <Card.Header as="h5">Customisation Options</Card.Header>
        <Card.Body>
          <h4>Strip Deadends</h4>
          <p>As noted above, I keep a store of all dead ends within the grid during generation.
            I can use this list to select any number at random and backtrack along until it joins an adjacent corridor in order to close the dead end.</p>
          <h4>Threats</h4>
          <p>Part of what makes a dungeon threatening and fun is the ability to add some risk and danger. I select the number of locations based purely on a setting that is done when creating the grid itself.
            Like the following options, they can only be placed on tiles that are available and are not a door tile.
          </p>
          <h4>Rewards</h4>
          <p>The reward locations are areas that will be for the player to find the loot. The idea for this will be that they will replenish the contents over a certain amount of time.
            A threat can be placed on the same tile and they favour rooms during generation.
          </p>
          <h4>Lore</h4>
          <p>Lore is the tile name given to areas for collectables. These favour dead ends and in the instance of no dead ends, rooms. Unlike rewards, once these have been collected, they will not re-appear in the player.
            My reasoning for this is so that if you find something interesting or exciting in, say, chunk 1565, at position (15,35), you can tell your friend about that and they will be able to travel to that exact position to find the item/info.
            In the game that I built it for however, each chunk will have two ingress/egress locations along each wall on the edge. </p>
          <h4>Tiling</h4>
          <p>I wanted this generation tool to work not just for the specific use case I have and part of that is accounting for the situation where you might not <i>need</i> tiling between dungeons.
            That is why there is a setting where it will not generate the connections along the periphery of the dungeon if turned off.</p>

          <h4>Extra</h4>
          <p>It is worth noting that after this generation is done, the grid is converted to a JSON file where each tile is given a letter representing the type of surface and any appending info regarding the options above.
            While doing this the Grid has to be doubled in size effectively making the walls their own tile with the type of wall.
          </p>
        </Card.Body>
      </Card>
      <br></br>
      <Card>
        <Card.Header as="h5">Unity Specific</Card.Header>
        <Card.Body>
          <h4>Stair location</h4>
          <p>The general rule for this is to find a dead end that exists to place a stair tile. The nearest to the middle is always preferred but not necessary. Alternatively a location will be created.</p>
          <h4>Light Location</h4>
          <p>There is a customisation option for dungeon generation for how well lit it will be. The intention is to have the corridors and rooms have lights distributed based on this.
          </p>
          <h4>Tile Selection</h4>
          <p>For deciding how to place the tile there is a series of steps. The first is to read in the JSON file generated and cycle through the 'map' section of it.
            In Unity there are collections of each tile type and variations on each one. This allows there to be a condition setting when generating the dungeon that will affect sprite selection.
            It also parses following ID's for placing rewards, threats, lore, and door sprites.
          </p>
          <h4>Tile Matching</h4>
          <p>For making sure the right tile is placed with the correct rotation and the correct sprite there is a custom masking system implemented.
            In the text file, there is no ID that represents a wall, the walls are placed because they're a 'void' tile but they have a floor tile next to them.
            This means that if the void tile is surrounded by other void tiles it will place a void sprite instead of a wall tile. </p>
          <p>This system has been set up so that the masks can just be added to with only the order needing some attention.
            By having this, it allows for much more intricate sprites such as corner tiles, fading-void tiles, isometric style walls, etc..</p>
          <p>Due to this seperation between tile type matching and sprite selection there can be as many variations of tiles and as many variations of tile sprites but they can be linked in what ever way required.
            A largely scalable dungeon generation system and tile system. Another benefit of this system is that it doesn't have to be sprites.
            The selection can just take items that are models instead and swap out meshes rather than sprites.
          </p>
        </Card.Body>
      </Card>
    </>
  );
}