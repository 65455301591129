import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './code.css';
import { SocialIcon } from 'react-social-icons';
import DungeonSidePanel from './visualElements/DungeonGenerationInfoPanel.js';
import LKWDSidePanel from './visualElements/LockwoodPublishingInfoPanel.js';
import XRSidePanel from './visualElements/XRGamesInfoPanel.js';
import TLNSidePanel from './visualElements/TLNInfoPanel.js';
import SidePanel from './visualElements/CodesidePanel.js'
import StartImage from './../images/icons/start.png';
import EduImage from './../images/icons/education.png';
import ProjImage from './../images/icons/project.png';
import GradImage from './../images/icons/graduation.png';
import YoutubeEmbed from "./visualElements/YoutubeEmbed.js";
import FYP from './../images/fyp/banner.png';
import JobImage from './../images/icons/job.png';
import DungeonImage from './../images/dungeonGeneration/screenshot_dungeon.png';
import ZavrImage from './../images/zavr.webp';
import CityImage from './../images/cityBuilder.gif';

function Code() {
    return (
        <div className='page'>
            <div className='side-panel'>
                <SidePanel />
            </div>
            <div className='page-header'>
                <h1>CODING</h1>

                <div className='icons_parent'>
                    <SocialIcon className='icons' url="https://www.linkedin.com/in/mjrobertson1/" style={{ height: 50, width: 50 }} />
                    <SocialIcon className='icons' url="https://github.com/MJRobertson" style={{ height: 50, width: 50 }} />
                    <SocialIcon className='icons' url="https://twitter.com/MartinR0bertson" style={{ height: 50, width: 50 }} />
                    <SocialIcon className='icons' url="https://www.youtube.com/c/MartinRobertson1" style={{ height: 50, width: 50 }} />

                </div>
            </div>
            <div className='page-contect'>

                <VerticalTimeline>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"

                        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#000' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                        date="2022 - Present"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<JobbImage />}
                    >
                        <h3 id='white' className="vertical-timeline-element-title">XR Games</h3>
                        <h4 id='white' className="vertical-timeline-element-subtitle">Lead Programmer</h4>
                        <p id='white'>
                            My role as Lead on ZAVR was to make a CO-OP VR version of Rebellion's flat screen Zombie Army games.
                            This project was to make a simship vr game that needed to work on 5 platforms with 10 headsets and significantly more hardware configurations.
                        </p>
                        <img className='image-fit' src={ZavrImage} alt={"ZombieArmyVR"} />
                        <XRSidePanel placement={'end'} />
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2021 - 2022"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<JobbImage />}
                    >
                        <h3 className="vertical-timeline-element-title">Lockwood Publishing</h3>
                        <h4 className="vertical-timeline-element-subtitle">Lead Software Developer - R&D</h4>
                        <p>
                            Leading the team on the development of the R&D application along with managing resource allocation between multiple teams and taking a key role in deciding the direction of future development projects.
                        </p>
                        <LKWDSidePanel placement={'end'} />
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="2021"
                        iconStyle={{ background: 'rgb(95, 217, 245)', color: '#fff' }}
                        icon={<ProjectImage />}
                    >
                        <h3 className="vertical-timeline-element-title">Dungeon Generation</h3>

                        <h4 className="vertical-timeline-element-subtitle">Personal Project</h4>
                        <p>
                            Developing a dungeon generating algorithm that can be reliably random and also will write the generated content to file for quick loading.
                            Also, include the ability to tile and chunk the content.
                        </p>
                        <img className='image-fit' src={DungeonImage} alt={"dungeon"} />
                        <DungeonSidePanel placement={'end'} />
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="March 2021"
                        iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                        icon={<EducationImage />}
                    >
                        <h3 className="vertical-timeline-element-title">Certified Scrum Master (CSM)</h3>
                        <h4 className="vertical-timeline-element-subtitle">agil8</h4>
                        <p>Credential ID: 001176205</p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2020 - 2021"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<JobbImage />}
                    >
                        <h3 className="vertical-timeline-element-title">Lockwood Publishing</h3>
                        <h4 className="vertical-timeline-element-subtitle">Senior Software Developer</h4>
                        <p>
                            Using Unity, AWS, and Mobile technologies to create an easily scalable and reliable test bed for R&D projects.
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2019 - 2020"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<JobbImage />}
                    >
                        <h3 className="vertical-timeline-element-title">The Leadership Network</h3>
                        <h4 className="vertical-timeline-element-subtitle">Lead Programmer</h4>
                        <p>
                            Using Unity, AWS, and VR Technologies to build virtual learning experiences and professional network applications.
                            Playing a key role from inception to release of the product.
                        </p>
                        <TLNSidePanel placement={'end'} />
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2019"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<JobbImage />}
                    >
                        <h3 className="vertical-timeline-element-title">Pocket Money Games/Perception XR</h3>
                        <h4 className="vertical-timeline-element-subtitle">Freelance Software Engineer</h4>
                        <p>
                            Using Unity and XR technologies to build interactive experiences and games both for external clients and in house projects.
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2018 - 2019"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<JobbImage />}
                    >
                        <h3 className="vertical-timeline-element-title">Hedgehog Lab</h3>
                        <h4 className="vertical-timeline-element-subtitle">Senior Unity Developer</h4>
                        <p>
                            Using Unity and XR technologies to build interactive experiences for a veriety of clients. Namely a VR interactive Art Exhibition.
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="2018"
                        iconStyle={{ background: 'rgb(95, 217, 245)', color: '#fff' }}
                        icon={<ProjectImage />}
                    >
                        <h3 className="vertical-timeline-element-title">Jampin</h3>
                        <h4 className="vertical-timeline-element-subtitle">Personal Project</h4>
                        <p>
                            Small game inside Unity to learn more about the pipeline of going from creation to publish for Android.
                        </p>
                        <YoutubeEmbed embedId='QQ7YX2rjNE8' />
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="2017"
                        iconStyle={{ background: 'rgb(95, 217, 245)', color: '#fff' }}
                        icon={<ProjectImage />}
                    >
                        <h3 className="vertical-timeline-element-title">Basic City Generator</h3>
                        <h4 className="vertical-timeline-element-subtitle">Personal Project</h4>
                        <p>
                            Very basic city generator made inside Unity.
                        </p>
                        <img className='image-fit' src={CityImage} alt={"city_gen"} />
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2016 - 2018"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<JobbImage />}
                    >
                        <h3 className="vertical-timeline-element-title">Atom Bank</h3>
                        <h4 className="vertical-timeline-element-subtitle">Developer</h4>
                        <p>
                            Devloping and maintaining the mobile application for Atom bank using C# and Native iOS/Android Additions.
                        </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="July 2016"
                        iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                        icon={<GradiateImage />}
                    >
                        <h3 className="vertical-timeline-element-title">Graduate Teesside University</h3>
                        <h4 className="vertical-timeline-element-subtitle">BSc (Hons) Computer Games Programming</h4>
                        <p>First Class Honours</p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="June 2016"
                        iconStyle={{ background: 'rgb(95, 217, 245)', color: '#fff' }}
                        icon={<ProjectImage />}
                    >
                        <h3 className="vertical-timeline-element-title">Dinogeddon</h3>
                        <h4 className="vertical-timeline-element-subtitle">Advanced Game Development</h4>
                        <p>
                            A team project consisting of a veriety of diciplines totalling 12 members. I was one of three programmers in the team. My main focus was AI and animations.
                        </p>
                        <YoutubeEmbed embedId='EA8r1mdqnkc' />
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="April 2016"
                        iconStyle={{ background: 'rgb(95, 217, 245)', color: '#fff' }}
                        icon={<ProjectImage />}
                    >
                        <h3 className="vertical-timeline-element-title">Intellegent Settlement Populations</h3>
                        <h4 className="vertical-timeline-element-subtitle">FYP & Dissertation</h4>
                        <p>
                            Using multiple Agents to self govern in an environment with limited resources.
                        </p>
                        <img className='image-fit' src={FYP} alt={"logo"} />
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="April 2016"
                        iconStyle={{ background: 'rgb(95, 217, 245)', color: '#fff' }}
                        icon={<ProjectImage />}
                    >
                        <h3 className="vertical-timeline-element-title">Sponza</h3>
                        <h4 className="vertical-timeline-element-subtitle">Graphics</h4>
                        <p>
                            Using C++ and OpenGL 3.3 to create a deferred renderer from supplied models.
                        </p>
                        <YoutubeEmbed embedId='FOgc6ro8hzU' />
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="September 2013"
                        iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                        icon={<EducationImage />}
                    >
                        <h3 className="vertical-timeline-element-title">Start Teesside University</h3>
                        <h4 className="vertical-timeline-element-subtitle">BSc (Hons) Computer Games Programming</h4>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="2011 - 2013"
                        iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                        icon={<EducationImage />}
                    >
                        <h3 className="vertical-timeline-element-title">Selby College</h3>
                        <h4 className="vertical-timeline-element-subtitle">A Level, Btec Level 3, AS Levels</h4>
                        <p>
                            Computing, IT, Applied ICT, Psychology
                        </p>
                    </VerticalTimelineElement>
                    <img id='background-image-middle-left' src={StartImage} alt={"logo"} />
                    <img id='background-image-lower-right' src={StartImage} alt={"logo"} />
                    <img id='background-image-bottom-left' src={StartImage} alt={"logo"} />
                    <VerticalTimelineElement
                        icon={<FirstImage />}
                        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                    />
                </VerticalTimeline>
            </div>
        </div>
    );
}
export default Code;

function FirstImage() {
    return (
        <img className='image' src={StartImage} alt={"logo"} />
    )
}

function EducationImage() {
    return (
        <img className='image' src={EduImage} alt={"logo"} />
    )
}

function ProjectImage() {
    return (
        <img className='image' src={ProjImage} alt={"logo"} />
    )
}

function GradiateImage() {
    return (
        <img className='image' src={GradImage} alt={"logo"} />
    )
}

function JobbImage() {
    return (
        <img className='image' src={JobImage} alt={"logo"} />
    )
}