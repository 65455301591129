import ParticleSystem from './visualElements/ParticleSystem.js';
import './mainIndex.css';
import { useNavigate } from "react-router-dom";
import { SocialIcon } from 'react-social-icons';

function Index() {
  const navigate = useNavigate();
  return (
    <div className="App">
      <header className="App-header">
        <h1>MJROBERTSON</h1>
        <div className='row'>
          <button onClick={() => navigate("/code")} class="button-30" role="button">Software</button>
        </div>
      </header>
      <div className='icons_parent'>
        <SocialIcon className='icons' url="https://www.linkedin.com/in/mjrobertson1/" style={{ height: 50, width: 50 }} />
        <SocialIcon className='icons' url="https://github.com/MJRobertson" style={{ height: 50, width: 50 }} />
        <SocialIcon className='icons' url="https://twitter.com/MartinR0bertson" style={{ height: 50, width: 50 }} />
      </div>

      <ParticleSystem />
    </div>
  );
}

export default Index;