
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Card, CardGroup } from 'react-bootstrap';
import './DungeonGenerationInfoPanel.css';
import MainDungeonShot from './../../images/dungeonGeneration/screenshot_dungeon2.png';
import d_origin from './../../images/dungeonGeneration/dungeonOrigin.png';
import d_text from './../../images/dungeonGeneration/dungeonText.png';
import d_render from './../../images/dungeonGeneration/dungeonRender.png';
/*const options = [
  {
    name: 'Enable backdrop (default)',
    scroll: false,
    backdrop: true,
  },
  {
    name: 'Disable backdrop',
    scroll: false,
    backdrop: false,
  },
  {
    name: 'Enable body scrolling',
    scroll: true,
    backdrop: false,
  },
  {
    name: 'Enable both scrolling & backdrop',
    scroll: true,
    backdrop: true,
  },
];*/

function TLNInfoPanel({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);
  //https://react-bootstrap.github.io/components/cards/
  return (
    <>
      <button className='astext' onClick={toggleShow}><u>Read More</u></button>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><h1>The Leadership Network</h1></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ContentBlock />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default TLNInfoPanel;

function ContentBlock() {
  return (
    <>
      <Card>
        <Card.Header ><h5>Overview </h5>
          <a href="https://theleadershipnetwork.com/" target="_blank">Visit their website</a>
        </Card.Header>
        <Card.Body>
          <Card>
            <Card.Body>
              <Card.Text>
                <h2>Key Tech</h2>
                <ul>
                  <li>Unity</li>
                  <li>C#</li>
                  <li>Photon Networking</li>
                  <li>Oculus/Vive VR/AR</li>
                  <li>AWS</li>
                  <li>WPF</li>
                  <li>Android</li>
                </ul>

                <h2>Role</h2>
                <p>My role at TLN was to lead a small team to develop VR applications that would be used by management found in fortune 500 companies
                  to learn the ways of Lean leadership, or, in the case of 'Live', be able to communicate internationally without having to fly to that office.
                  While working for TLN the team and I managed to release the full version of Distributed Lean Leaders for the HTC Vive. This was a 6-9 hour VR experience
                  on learning the key principles of lean and provided several quiz sections that would be uploaded to a user info panel. This bit of software amongst other things sold over £3 million
                  during my time there. We also developed a cutting edge VR networking product that would allow up to 20 people to meet in virtual reality. I was a key in the development of
                  authenticating the login, communicating the information and implementing natural interaction. An exciting bit of tech developed was a real time whiteboard that anyone could draw on.
                  Finally, all these bits of software were brought together using a WPF application and AWS to host.
                  It would inform customers when a new version of the app had been uploaded and was ready for download.</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </>
  );
}