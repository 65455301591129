
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import menuBurger from './../../images/icons/menu.png';
import './CodesidePanel.css';
import { useNavigate } from "react-router-dom";

/*const options = [
  {
    name: 'Enable backdrop (default)',
    scroll: false,
    backdrop: true,
  },
  {
    name: 'Disable backdrop',
    scroll: false,
    backdrop: false,
  },
  {
    name: 'Enable body scrolling',
    scroll: true,
    backdrop: false,
  },
  {
    name: 'Enable both scrolling & backdrop',
    scroll: true,
    backdrop: true,
  },
];*/

function OffCanvasExample({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  return (
    <>
      <Button className='button-style-1' onClick={toggleShow} >
        <img className='button-style-image' src={menuBurger} alt={"dungeon"} />
      </Button>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ContentBlock />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OffCanvasExample;

function ContentBlock() {
  const navigate = useNavigate();
  return (
    <>
      <Button className='button-style-smart' onClick={() => navigate("/")}>
        <h1 className='button-text'>HOME</h1>
      </Button>
    </>
  )
}