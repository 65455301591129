import Index from './pages/mainIndex.js';
import Code from './pages/code.js';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

export default function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/code" element={<Programming />} />
          <Route path="/cini" />
        </Routes>
      </div>
    </Router>
  );
}


function Home() {
  return (
    <Index />
  );
}

function Programming() {
  return (
    <Code />
  );
}